<script setup lang="ts">
    import type { IntroWidgetData } from '~/@types/cms';

    interface IntroWidgetProps {
        data: IntroWidgetData;
    }
    const props = defineProps<IntroWidgetProps>();

    const appStore = useAppStore();

    const subtitle = computed(() => {
        return textLinks(props.data.value?.subtitle, appStore.getReference);
    });
</script>
<template>
    <mol-intro
        class="org-intro-widget"
        :title="data.value?.title"
        :description="subtitle"
        :anchor="data?.anchor"
        center>
        <div v-if="data.link || data.link2">
            <atm-link
                v-if="data.link"
                :link="data.link"
                styling="solid-secondary" />
            <atm-link
                v-if="data.link2"
                :link="data.link"
                styling="solid-secondary" />
        </div>
    </mol-intro>
</template>
